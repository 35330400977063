export const textTran: any = {
    us: {
        btnText: 'Subscribe',
        placeholder: 'Email Address',
        tipsEmpty: 'Please enter your email address.',
        tipsTypeError: 'Please enter a valid email address (Example: name@domain.com).',
        successTips: 'Success!',
        tipsCheck: "Please read and agree to EcoFlow's Privacy Policy and Terms of Use",
        tipsProductEmpty: 'Please select Your Subscription Products to get the latest product news.',
    },
    kr: {
        btnText: '구독하기',
        placeholder: '이메일 주소',
        tipsEmpty: '이메일 주소를 입력하십시오。',
        tipsTypeError: '올바른 전자 메일 주소를 입력하십시오 (예:name@domain.com)。',
        successTips: '구독해 주셔서 감사드립니다!',
        tipsCheck: 'EcoFlow의 개인 정보 보호 정책 및 이용 약관을 읽고 동의하십시오.',
        tipsProductEmpty: 'Please select Your Subscription Products to get the latest product news.',
    },
    de: {
        btnText: 'Abonnieren',
        placeholder: 'E-Mail Adresse',
        tipsEmpty: 'Geben bitte Sie Ihre gültige E-Mail-Adresse ein.',
        tipsTypeError: 'Geben bitte Sie Ihre gültige E-Mail-Adresse ein.',
        successTips: 'Erfolgreich!',
        tipsCheck: 'Bitte stimmen Sie der Datenschutzrichtlinie und den Nutzungsbedingungen zu.',
        tipsProductEmpty: 'Please select Your Subscription Products to get the latest product news.',
    },
    fr: {
        btnText: 'S’abonner',
        placeholder: 'Adresse e-mail',
        tipsEmpty: 'Veuillez saisir votre adresse e-mail.',
        tipsTypeError: 'Veuillez saisir une adresse e-mail valide (Exemple : nom@domaine.com).',
        successTips: 'Réussi !',
        tipsCheck: "Veuillez lire et accepter la politique de confidentialité et les conditions d'utilisation d'EcoFlow.",
        tipsProductEmpty: 'Please select Your Subscription Products to get the latest product news.',
    },
    es: {
        btnText: 'Suscribirse',
        placeholder: 'Dirección de correo',
        tipsEmpty: 'Por favor, introduzca su dirección de correo electrónico.',
        tipsTypeError: 'Ingrese una dirección de correo electrónico válida (Ejemplo: nombre@dominio.com).',
        successTips: '¡Éxito!',
        tipsCheck: 'Lea y acepte la Política de privacidad y los Términos de uso de EcoFlow',
        tipsProductEmpty: 'Please select Your Subscription Products to get the latest product news.',
    },
    it: {
        btnText: 'Iscriviti',
        placeholder: 'Indirizzo e-mail',
        tipsEmpty: 'Inserisci il tuo indirizzo email.',
        tipsTypeError: 'Inserisci un indirizzo email valido (Esempio: nome@dominio.com).',
        successTips: 'Successo!',
        tipsCheck: "Si prega di leggere e accettare l'Informativa sulla privacy e i Termini di utilizzo di EcoFlow",
        tipsProductEmpty: 'Please select Your Subscription Products to get the latest product news.',
    },
    jp: {
        btnText: 'メルマガ登録',
        placeholder: 'メールアドレス',
        tipsEmpty: 'メールアドレスを入力してください',
        tipsTypeError: '有効なメールアドレスを入力してください（例：name@domain.com）',
        successTips: 'ご登録ありがとうございます！',
        tipsCheck: '「EcoFlowのプライバシーポリシーと利用規約に同意する」にチェックを入れてください',
        productsCheckTip: 'Please Select Your Subscription Products',
        tipsProductEmpty: 'Please select Your Subscription Products to get the latest product news.',
    },
    br: {
        btnText: 'Inscrever-se',
        placeholder: 'Endereço de e-mail',
        tipsEmpty: 'Por favor, insira seu endereço de e-mail.',
        tipsTypeError: 'Por favor, insira um endereço de e-mail válido (Exemplo: nome@dominio.com).',
        successTips: 'Cadastrado com sucesso!',
        tipsCheck: 'Leia e concorde com a Política de Privacidade e os Termos de Uso da EcoFlow',
        tipsProductEmpty: 'Selecione Seus produtos de assinatura para receber as últimas notícias sobre produtos.',
    },
};

export const termsTran: any = {
    us: `I agree to EcoFlow's <a href="https://account.ecoflow.com/agreement/en-us/PrivacyPolicy.html" target="_blank">Privacy Policy</a> and <a href="https://us.ecoflow.com/policies/terms-of-service" target="_blank">Terms of Service</a>.`,
    ca: `I agree to EcoFlow's <a href="https://account.ecoflow.com/agreement/en-ca/PrivacyPolicy.html" target="_blank">Privacy Policy</a> and <a href="https://ca.ecoflow.com/policies/terms-of-service" target="_blank">Terms of Service</a>.`,
    jp: 'EcoFlowの<a href="https://account.ecoflow.com/agreement/ja-jp/PrivacyPolicy.html" target="_blank">プライバシーポリシー</a>と<a href="https://account.ecoflow.com/agreement/ja-jp/TermsOfUse.html" target="_blank">利用規約</a>に同意する',
    kr: '에코플로우의 <a href="https://account.ecoflow.com/agreement/ko-kr/PrivacyPolicy.html" target="_blank">프라이버시 정책</a> 및 <a href="https://account.ecoflow.com/agreement/ko-kr/TermsOfUse.html" target="_blank">이용약관에</a> 동의합니다.',
    au: `I agree to EcoFlow's <a href="https://au.ecoflow.com/policies/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://au.ecoflow.com/policies/terms-of-service" target="_blank">Terms of Service</a>.`,
    za: `I agree to EcoFlow's <a href="https://za.ecoflow.com/policies/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://za.ecoflow.com/policies/terms-of-service" target="_blank">Terms of Service</a>.`,
    eu: `I agree to EcoFlow's <a href="https://eu.ecoflow.com/policies/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://eu.ecoflow.com/policies/terms-of-service" target="_blank">Terms of Service</a>.`,
    de: `Ich erkläre mich mit den <a href="https://de.ecoflow.com/policies/privacy-policy" target="_blank">Datenschutzrichtlinien</a> und <a href="https://de.ecoflow.com/policies/terms-of-service" target="_blank">Nutzungsbedingungen</a> von EcoFlow einverstanden.`,
    fr: `J'accepte la <a href="https://fr.ecoflow.com/policies/privacy-policy" target="_blank">Politique de confidentialité</a> et les <a href="https://fr.ecoflow.com/policies/terms-of-service" target="_blank">Conditions d'utilisation</a> d'EcoFlow.`,
    es: `Acepto la <a href="https://es.ecoflow.com/policies/privacy-policy" target="_blank">Política de privacidad</a> y los <a href="https://es.ecoflow.com/policies/terms-of-service" target="_blank">Términos de servicio</a> de EcoFlow.`,
    it: `Accetto l'<a href="https://it.ecoflow.com/policies/privacy-policy" target="_blank">Informativa sulla privacy</a> e i <a href="https://it.ecoflow.com/policies/terms-of-service" target="_blank">Termini di servizio</a> di EcoFlow.`,
    uk: `I agree to EcoFlow's <a href="https://uk.ecoflow.com/policies/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://uk.ecoflow.com/policies/terms-of-service" target="_blank">Terms of Service</a>.`,
    br: `Eu concordo com os <a href="https://account.ecoflow.com/agreement/pt-br/TermsOfUse.html" target="_blank">Termos de Uso</a> e a <a href="https://account.ecoflow.com/agreement/pt-br/PrivacyPolicy.html" target="_blank">Política de Privacidade</a>.`,
};
