'use client';
import { fetchPost } from 'lowcode/utils/service/FetchClient';
import { baseUrl } from 'lowcode/utils/service/baseUrl';
export const sendEmarsysEmail = async (locale: string, data: any) => {
    const res = await fetchPost(`${baseUrl(locale)}/subscribe/shopify`, locale, data, {
        headers: {
            apiKey: '527760f6df93c0d6e98609fa8e8a1f4a',
        },
    });
    return res;
};

export const sendMultiEmarsysEmail = async (locale: string, data: any) => {
    const res = await fetchPost(`${baseUrl(locale)}/subscribe/new`, locale, data, {
        headers: {
            apiKey: '527760f6df93c0d6e98609fa8e8a1f4a',
        },
    });
    return res;
};
