'use client';
import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { sendEmarsysEmail, sendMultiEmarsysEmail } from './apis';
import { termsTran, textTran } from './config';
import './index.scss';

interface fieldsType {
    id: string;
    type: string;
    value: number | number[];
    overwrite: boolean;
}
interface productType {
    value: number | string;
    label: string;
    checked?: boolean;
    fields: fieldsType[];
}
interface tipsType {
    /**
     * 按钮文案
     */
    btnText?: string;
    /**
     * 输入框提示语
     */
    placeholder?: string;
    /**
     * 提交时文案为空的提示语
     */
    tipsEmpty?: string;
    /**
     * 邮箱格式错误提示语
     */
    tipsTypeError?: string;
    /**
     * 成功订阅提示语
     */
    successTips?: string;
    /**
     * 勾选提示语
     */
    tipsCheck?: string;
    /**
     * 没有选择产品时
     */
    tipsProductEmpty?: string;
    /**
     * 提交订阅失败时文案
     */
    tipsFailed?: string;
}

/**
 * 维护:谢保林
 *
 * 邮箱订阅
 */
export default function EfEmarsysEmail({
    contactListId,
    fields,
    productList = [],
    isMulti = false,
    source = 'WEB_NEW_PRODUCT_SUB',
    oldSource,
    email = '',
    className,
    onBeforeSendCallback,
    callback,
    tipsObj = {},
    needTerms = false,
    isUnDefaultCheckArray = ['uk', 'eu', 'de', 'fr', 'it', 'es', 'kr'],
    theme,
    isOpenOutTips,
    isShowOutTips,
    outTipsText,
    gtm_event_name = 'activity_submit',
    termsHtml = '',
    text = '',
    custom_gtm = false,
    callbackCheck,
    configTheme = '',
    configCheck,
    termsTextColor = '',
}: {
    /**
     * contactListId
     */
    contactListId: string | string[];
    /**
     * fields 数组
     */
    fields?: fieldsType[];
    /**
     * 订阅产品列表
     */
    productList?: productType[];
    /**
     * 邮箱
     */
    email?: string;
    /**
     * isMulti 是否同时传入多个contactListId
     */
    isMulti?: boolean;
    /**
     * source 订阅来源
     */
    source?: string;
    /**
     * source 订阅来源(旧接口)
     */
    oldSource?: string;
    /**
     * 样式名
     */
    className?: string;
    /**
     * 接口发送前回调
     *
     * 如果在 onBeforeSendCallback return true,则不会执行自带的订阅接口
     *
     * data 用于接收输入框里的数据
     *
     * callback 用于执行成功后执行埋点发送
     */
    onBeforeSendCallback?: (
        data: { email: string; contactListId: string | string[]; fields: fieldsType[] },
        gtmCallback?: (status: string, gtmParams: any) => void,
    ) => boolean | undefined;
    /**
     * 接口发送后回调
     */
    callback?: (type: 'success' | 'fail', btnText: string | undefined) => void;
    /**
     * 提示语对象
     */
    tipsObj?: tipsType;
    /**
     * 是否需要隐私协议
     */
    needTerms?: boolean;
    /**
     * 隐私协议的内容(不传入的话则会使用写好的一套)
     */
    termsHtml?: string;
    /**
     * 隐私协议默认不选中站点(默认欧洲6国是不能选中的)
     */
    isUnDefaultCheckArray?: string[];
    /**
     * 主题色
     */
    theme?: 'default' | 'black' | 'white';
    /**
     * text文本
     */
    text?: string;
    /**
     * 外部是否传入提示语--是否启用
     */
    isOpenOutTips?: boolean;
    /**
     * 外部是否传入提示语--是否展示
     */
    isShowOutTips?: boolean;
    /**
     * 外部是否传入提示语--提示语文案
     */
    outTipsText?: string;
    /**
     * 埋点数据--event_name
     */
    gtm_event_name?: string;
    /**
     * 埋点数据自定义
     */
    custom_gtm?: boolean;
    /**
     * callbackCheck
     */
    callbackCheck?: any;
    /**
     * 配置主题色
     */
    configTheme?: string;
    /**
     * 配置是否勾选
     */
    configCheck?: boolean;

    /**
     * 配置Terms字体颜色
     */
    termsTextColor?: string;
}) {
    const { locale } = getLocal();
    const textObj = textTran[locale] || textTran['us'];
    const isUnDefaultCheck = isUnDefaultCheckArray.includes(locale); // 是否不默认选中
    const [check, setCheck] = useState<boolean>(['true', 'false'].includes(String(configCheck)) ? configCheck : !isUnDefaultCheck);
    const [compId, setCompId] = useState<string>(''); //勾选ID
    const [value, setValue] = useState(email || ''); //输入框值
    const [tipsMsg, setTipsMsg] = useState<string | undefined>(); // 提示语文案
    const [tipsType, setTipsType] = useState<string>(''); // 提示类型
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/; // 校验邮箱格式
    // 邮箱校验
    let fields2 = JSON.parse(JSON.stringify(fields) || '[]');
    // 提示语
    const tipsObj2: tipsType = {
        successTips: tipsObj.successTips || textObj.successTips, //文案-成功提示语
        btnText: tipsObj.btnText || textObj.btnText, // 文案--提交按钮
        placeholder: tipsObj.placeholder || textObj.placeholder, // 文案--输入框提示语
        tipsEmpty: tipsObj.tipsEmpty || textObj.tipsEmpty, // 文案--空时提示语
        tipsTypeError: tipsObj.tipsTypeError || textObj.tipsTypeError, // 文案--格式不对
        tipsProductEmpty: tipsObj.tipsProductEmpty || textObj.tipsProductEmpty, // 文案--产品没选择
        tipsCheck: tipsObj.tipsCheck || textObj.tipsCheck, //文案--没有勾选时提示语
        tipsFailed: tipsObj.tipsFailed || '',
    };
    const prefixCls = `ef-emarsys-email`;
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
        [`${prefixCls}-type-${tipsType}`]: tipsType,
        [`${prefixCls}-theme-${theme}`]: theme,
    });
    const hasProduct = !!productList?.length;
    const [productList2, setProductList2] = useState(productList || []);

    // 发送埋点(内部接口发送后用)
    const sendGtmHandle = (status: string) => {
        let tag = 'none';
        if (needTerms) {
            tag = 'Agree';
        }
        !custom_gtm &&
            gtm.push({
                event: 'uaEvent',
                event_name: gtm_event_name,
                button_name: tipsObj2.btnText,
                status: status, //success|fail 提交状态
                tag: tag, //Agree|Disagree|None 用户是否同意隐私政策
            });
    };

    // 发送埋点(外部接口发送后用)gtmParams 用于外层参数调用多传的参数
    const sendGtmHandleOut = (status: string, gtmParams?: any) => {
        let tag = 'none';
        if (needTerms) {
            tag = 'Agree';
        }
        !custom_gtm &&
            gtm.push({
                event: 'uaEvent',
                event_name: gtm_event_name,
                button_name: tipsObj2.btnText,
                status: status, //success|fail 提交状态
                tag: tag, //Agree|Disagree|None 用户是否同意隐私政策
                ...gtmParams,
            });
    };

    // 输入值变化
    const changeHandle = (e: any) => {
        const val = e.target.value;
        setValue(val);
        setTipsMsg('');
    };

    // 点击
    const clickHandle = async () => {
        const value2 = value.trim();

        // 非空校验
        if (!value2) {
            setTipsType('error');
            return setTipsMsg(tipsObj2.tipsEmpty);
        }
        // 格式校验
        if (!emailRegex.test(value2)) {
            setTipsType('error');
            return setTipsMsg(tipsObj2.tipsTypeError);
        }
        if (hasProduct && !getProductCheck()) {
            setTipsType('error');
            return setTipsMsg(tipsObj2.tipsProductEmpty);
        }
        // 是否开启外部tips
        if (isOpenOutTips && isShowOutTips && outTipsText) {
            setTipsType('error');
            return setTipsMsg(outTipsText);
        }
        // 校验是否勾选隐私协议
        if (needTerms && !check) {
            return setTipsMsg(tipsObj2.tipsCheck);
        }

        if (hasProduct && !callbackCheck) {
            fields2 = JSON.parse(JSON.stringify(fields));
            productList2.forEach((item: productType) => {
                if (item.checked) {
                    const fieldsB = JSON.parse(JSON.stringify(item?.fields || []));
                    fieldsB?.forEach((cusField: fieldsType) => {
                        // 不存在勾选项的Filed则push添加该Field
                        if (!fields2?.find((exF: fieldsType) => exF.id === cusField.id)) {
                            fields2.push(...fieldsB);
                        } else {
                            // 已存在Filed, 则判断已存在的Filed中的type是否为multuple且value数组中是否已存在勾选项的value值，不存在则Field的value添加push新值
                            fields2?.map((exF: fieldsType) => {
                                const fv = Array.isArray(cusField.value) ? cusField.value[0] : cusField.value;
                                if (exF.type === 'multiple' && Array.isArray(exF.value) && !exF.value.includes(fv)) {
                                    exF.value.push(fv);
                                }
                            });
                        }
                    });
                }
            });
        }

        // 必须有传值
        if (!contactListId) {
            return null;
        }
        const data = {
            email: value2,
            contactListId: contactListId,
            fields: fields2,
        };

        if (onBeforeSendCallback) {
            const flag = onBeforeSendCallback(data, (status: string, gtmParams: any) => {
                sendGtmHandleOut(status, gtmParams); //发送埋点
                if (status === 'success') {
                    successHandle(false);
                } else if (status === 'fail') {
                    failHandle();
                }
            });
            // 如果在 onBeforeSendCallback return true,则不会执行自带的订阅接口
            if (flag) {
                return false;
            }
        }
        await sendEmailHandle(data);
    };
    // 执行成功
    const successHandle = (flag = true) => {
        setTipsMsg(tipsObj2.successTips); //成功
        setTimeout(() => {
            setTipsMsg('');
            setTipsType('');
        }, 3000);
        setTipsType('success');
        callback && callback('success', tipsObj2.btnText);
        setValue('');
        !!flag && sendGtmHandle('success');
    };
    // 执行失败
    const failHandle = (message?: string) => {
        setTipsType('error');
        setTipsMsg(tipsObj2.tipsFailed || message || 'fail'); // 后台接口提示
        callback && callback('fail', tipsObj2.btnText);
        sendGtmHandle('fail');
    };
    // 执行发送
    const sendEmailHandle = async (data: any) => {        
        if (isMulti) {
            const { email, fields, contactListId } = data;
            const res: any = await sendMultiEmarsysEmail(locale, {
                contactListId: contactListId[0],
                email,
                fields,
                source,
                otherContactListIds: contactListId.slice(1),
            });
            if (res.code === '0') {
                successHandle();
            } else {
                failHandle(res.message);
            }
        } else {
            const res: any = await sendEmarsysEmail(locale, {
                ...data,
                source: oldSource || undefined,
            });
            if (res.code === '0') {
                successHandle();
            } else {
                failHandle(res.message);
            }
        }
    };

    // 点击勾选
    const handleCheck = (e: any) => {
        const check2 = e.target.checked;
        setCheck(check2);
        if (tipsMsg === tipsObj2.tipsCheck && check2) {
            setTipsMsg('');
        }
    };

    const handleFocus = () => {
        if (tipsMsg) {
            if (!check) {
                return setTipsMsg(tipsObj2.tipsCheck);
            }
            if (hasProduct && productList2?.every((p) => !p.checked)) {
                return setTipsMsg(tipsObj2.tipsProductEmpty);
            }
            setTipsMsg(check ? '' : tipsObj2.tipsCheck);
        }
    };

    // 校验 productList2 是否有点击了
    const getProductCheck = () => {
        let flag = false;
        productList2.map((item1: productType) => {
            if (item1.checked) {
                flag = true;
            }
        });
        return flag;
    };

    // 选择产品
    const productHandle = (index: number, flag: boolean) => {
        const productList3 = productList2;
        productList3[index].checked = flag;

        setTipsMsg('');
        setProductList2(productList3);
        callbackCheck && callbackCheck(productList3);
    };

    useEffect(() => {
        setCompId(`${Math.random()}`);
    }, []);

    return (
        <div className={classes}>
            {hasProduct && (
                <div className={`${prefixCls}-product-list`}>
                    {productList2.map((item: productType, index: number) => {
                        const idA = `product-item-${value}-${index}`;
                        return (
                            <div key={index} className={`${prefixCls}-product-item`}>
                                <input
                                    type="checkbox"
                                    className={`${prefixCls}-product-item-input`}
                                    readOnly
                                    id={idA}
                                    defaultChecked={item.checked}
                                    onFocus={() => {
                                        if (tipsMsg && tipsMsg === tipsObj2.tipsProductEmpty) {
                                            setTipsMsg('');
                                        }
                                    }}
                                    onChange={(e: any) => {
                                        productHandle(index, e.target.checked);
                                    }}
                                />
                                <label htmlFor={idA} className={`${prefixCls}-product-item-label`}>
                                    {item.label}
                                </label>
                            </div>
                        );
                    })}
                </div>
            )}
            {text && <p className={`${prefixCls}-text`}>{text}</p>}
            <div className={`${prefixCls}-in`}>
                <div className={`${prefixCls}-c`}>
                    <input
                        type="text"
                        className={`${prefixCls}-input`}
                        value={value}
                        onFocus={handleFocus}
                        onChange={changeHandle}
                        placeholder={tipsObj2.placeholder}
                        style={configTheme ? { borderColor: configTheme } : {}}
                    />
                </div>
                <div className={`${prefixCls}-r`} onClick={clickHandle} style={configTheme ? { background: configTheme } : {}}>
                    {tipsObj2.btnText}
                </div>
            </div>
            {tipsMsg && <div className={`${prefixCls}-errors`}>{tipsMsg}</div>}
            {needTerms && (
                <div className={`${prefixCls}-terms ${configTheme ? 'config' : ''}`} onChange={handleCheck}>
                    {configTheme && (
                        <span
                            className={`cover ${check ? 'check' : ''}`}
                            style={{ border: `1px solid ${configTheme}`, background: check ? configTheme : 'transparent' }}
                        ></span>
                    )}
                    <input id={compId} type="checkbox" className={`${prefixCls}-checkbox`} checked={check} readOnly />
                    <label htmlFor={compId} className={`${prefixCls}-terms-text ${termsTextColor ? 'customize' : ''}`}>
                        <span dangerouslySetInnerHTML={{ __html: termsHtml || termsTran[locale] || termsTran['us'] }} />
                    </label>
                </div>
            )}
        </div>
    );
}
